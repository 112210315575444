import { Helmet } from 'react-helmet'
import Layout from '../Layout'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import { PageDataContext } from 'contexts/PageDataContext'
import DynamicComponent, {
  ComponentDataModel,
} from 'components/DynamicComponent/DynamicComponent'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import { graphql } from 'gatsby'
import { PageDataBaseType } from 'models/page-model'
import classNames from 'classnames'

export default function PageDefaultCategorieArticoli(
  props: PageCategoriaArticoliDataProps
) {
  const {
    data: { page },
    pageContext: { title },
  } = props

  return (
    <A2ADataContextProvider>
      <Layout pageTitle={page?.field_meta_tags_taxonomy_fe?.title || title}>
        <Helmet
          bodyAttributes={{
            class: classNames(
              'a2a-template-categorie-articoli',
              'a2a-navigation-main',
              'a2a-navigation-full'
            ),
          }}
        >
          {page?.field_meta_tags_taxonomy_fe?.description && (
            <meta
              name="description"
              content={page?.field_meta_tags_taxonomy_fe?.description}
            />
          )}
        </Helmet>

        <CanonicalUrlComponent url={page?.path?.alias} />

        <PageDataContext.Provider
          value={{
            data: page,
          }}
        >
          {page.relationships?.field_struttura_content_taxonomy &&
            page.relationships.field_struttura_content_taxonomy.map(
              (field, i) => (
                <DynamicComponent
                  key={i}
                  component={field.__typename}
                  data={field}
                  related={{
                    parent: undefined,
                    siblings: {
                      before:
                        page.relationships.field_struttura_content_taxonomy[
                          i - 1
                        ] ?? undefined,
                      after:
                        page.relationships.field_struttura_content_taxonomy[
                          i + 1
                        ] ?? undefined,
                    },
                  }}
                />
              )
            )}
        </PageDataContext.Provider>
      </Layout>
    </A2ADataContextProvider>
  )
}

interface PageCategoriaArticoliDataProps {
  data: {
    page: PageDataItemType
  }
  pageContext: {
    id: string
    drupal_id: string
    title: string
  }
}

interface PageDataItemType extends PageDataBaseType {
  id: string
  drupal_id: string
  description: {
    processed: string
    format: string
  }
  name: string
  path: {
    alias: string
  }
  internal: {
    type: 'taxonomy_term__categorie_articoli_nuovo_sito'
  }
  field_meta_tags_taxonomy_fe?: {
    title?: string
    description?: string
  }
  status: boolean
  relationships: {
    field_breadcrumb_content?: {
      __typename: string
      id: string
      drupal_id: string
      internal: {
        type: string
      }
      parent_type: string
      parent_field_name: string
      field_link_breadcrumb:
        | {
            title: string
            uri: string
          }[]
        | null
    } | null
    field_struttura_content_taxonomy: ComponentDataModel[]
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: taxonomyTermCategorieArticoliNuovoSito(id: { eq: $id }) {
      id
      drupal_id
      name
      description {
        processed
        format
      }
      path {
        alias
      }
      internal {
        type
      }
      field_meta_tags_taxonomy_fe {
        description
        title
      }
      status
      relationships {
        field_breadcrumb_content {
          ...BreadcrumbsFe
        }
        ...TaxonomyTermCategorieArticoliNuovoSitoStruttura
      }
    }
  }

  fragment TaxonomyTermCategorieArticoliNuovoSitoStruttura on taxonomy_term__categorie_articoli_nuovo_sitoRelationships {
    field_struttura_content_taxonomy {
      __typename
      ...ParagraphAdditionalBannerFe
      ...ParagraphArticoliFe
      ...ParagraphBannerFe
      ...ParagraphDoorwaysWrapperFe
      ...ParagraphFiltriPerDataFe
      ...ParagraphGalleryFe
      ...ParagraphHeroWrapperFe
      ...ParagraphMarginiFe
      ...ParagraphRiferimentoMagazineFe
      ...ParagraphSezioneFe
    }
  }
`
